<template>
  <div class="appointment-checkout-receipt">
    <!-- 服務資訊 -->
    <div>
      <div class="detail-row cursor-pointer" @click="expand.services = !expand.services">
        <div class="flex items-center">
          <p>結帳總金額</p>
          <MaterialIcon v-if="!expand.services">keyboard_arrow_down</MaterialIcon>
          <MaterialIcon v-else>keyboard_arrow_up</MaterialIcon>
        </div>
        <p v-show="showControl.itemPrice">$ {{ displayData.totalItemsPrice }}</p>
      </div>
      <el-collapse-transition>
        <div v-show="expand.services" class="detail-row-container">
          <div v-for="(group, groupIdx) in displayData.groupDetailData" :key="groupIdx">
            <div v-for="(item, itemIdx) in group" :key="itemIdx" class="detail-row text-sub text-gray-60">
              <p>{{ item.name }} x{{ item.quantity }}</p>
              <p v-show="showControl.itemPrice">$ {{ item.total }}</p>
            </div>
          </div>
        </div>
      </el-collapse-transition>
    </div>

    <!-- 回饋金資訊 -->
    <div v-if="showControl.cashbackUse" class="detail-row">
      <p>回饋金折抵</p>
      <p>-$ {{ cashbackUse }}</p>
    </div>

    <!-- 折抵資訊 -->
    <div>
      <div v-for="discountGroup in displayData.flattenDiscountData" :key="discountGroup.key">
        <div>
          <div class="detail-row cursor-pointer" @click="expand[discountGroup.key] = !expand[discountGroup.key]">
            <div class="flex items-center">
              <p>{{ discountGroup.name }}</p>
              <MaterialIcon v-if="!expand[discountGroup.key]">keyboard_arrow_down</MaterialIcon>
              <MaterialIcon v-else>keyboard_arrow_up</MaterialIcon>
            </div>
            <p>-$ {{ discountGroup.totalDIscount }}</p>
          </div>

          <el-collapse-transition>
            <div v-show="expand[discountGroup.key]" class="detail-row-container">
              <div v-for="(discount, discountIdx) in discountGroup.items" :key="discountIdx" class="sub-detail-row">
                <p>{{ discount.discountName }}</p>
                <p>-$ {{ discount.discountPrice }}</p>
              </div>
            </div>
          </el-collapse-transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref, reactive, set } from 'vue'
import { get, forEach, reduce, some, filter, toNumber, omit, find } from 'lodash'
import { appointmentModeType, useCreateAappointment } from '@/use/useCreateAppointment'
import { passValue } from '@/utils/helper';

export default defineComponent({
  name: 'AppointmentCheckoutReceipt',
  setup (props, { emit }) {
    const { cart, context, configData, memberData } = useCreateAappointment()
    const showDetail = ref(false)
    const checkoutDetailData = computed(() => get(context, 'checkoutDetail'))
    const chargeType = computed(() => get(context.checkoutDetail, 'chargeType'))
    const cashbackUse = computed(() => get(context.discount, 'cashback.use'))

    const showControl = computed(() => {
      const controls = {
        cashbackUse: false,
        itemPrice: true,
      }
      const cashbackLimit = get(configData.checkout, 'discount.cashback.limit')
      const cashbackBalance = get(memberData, 'cashback.balance')

      if (chargeType.value === 'none') {
        controls.itemPrice = false
      }

      if (cashbackUse.value) {
        controls.cashbackUse = true
      }

      if (toNumber(cashbackUse.value) > toNumber(cashbackLimit)) {
        controls.cashbackUse = false
      } else if (toNumber(cashbackUse.value) > toNumber(cashbackBalance)) {
        controls.cashbackUse = false
      }

      return controls
    })

    const discountsConfig = {
      classTicketRecord: { label: '堂票折抵', value: 'classTicketDiscount' },
    }

    const enableCashbackDiscount = computed(() => {
      return (
        get(configData.checkout, 'enableCashbackDeduction') && chargeType.value === 'checkout'
      )
    })

    const usedClassTickets = computed(() => {
      return some(cart.value, (item) => {
        return get(item, 'classTickets.length')
      })
    })

    const expand = reactive({
      services: true,
    })

    const groupItem = (items) => {
      return reduce(
        items,
        (acc, item) => {
          if (!acc[item.groupId]) {
            acc[item.groupId] = []
          }

          if (item.type === 'appointmentSubService') {
            const info = find(get(checkoutDetailData.value, 'infos', []), {
              subServiceId: item.originId,
            })

            item.name = `${get(info, 'serviceName')} (${item.name})`
          }

          acc[item.groupId].push(item)
          return acc
        },
        {},
      )
    }

    const mergeOriginItems = (items) => {
      return reduce(
        items,
        (acc, item) => {
          const itemTotalPrice = item.total
          const customOriginId = `${item.originId}-${itemTotalPrice}`
          if (!acc[customOriginId]) {
            acc[customOriginId] = item
          } else {
            acc[customOriginId].quantity += item.quantity
            acc[customOriginId].total += item.total
          }
          return acc
        },
        {},
      )
    }

    const groupDiscount = (discounts) => {
      const group = reduce(
        discounts,
        (acc, discount) => {
          if (!acc[discount.discountType]) {
            set(expand, discount.discountType, false)

            let discountName = get(discountsConfig, `${discount.discountType}.label`)
            if (!discountName) {
              discountName = discount.discountName || '折扣'
            }

            acc[discount.discountType] = {
              key: discount.discountType,
              name: discountName,
              items: [],
            }
          }
          acc[discount.discountType].items.push(discount)
          return acc
        },
        {},
      )

      forEach(group, (item) => {
        item.totalDIscount = reduce(item.items, (acc, item) => acc + item.discountPrice, 0)
      })

      return group
    }

    const displayData = computed(() => {
      const details = get(checkoutDetailData.value, 'details', [])
      const detailData = []
      const discountData = []

      for (const detail of details) {
        if (get(detail.discountDetails, 'length')) {
          discountData.push(...detail.discountDetails)
        }

        if (get(detail.items, 'length')) {
          detailData.push(...detail.items)
        }
      }

      if (context.appointmentMode === appointmentModeType.batchOrder) {
        return {
          groupDetailData: groupItem(mergeOriginItems(passValue(detailData))),
          flattenDiscountData: omit(groupDiscount(passValue(discountData)), ['cashback']),
          totalItemsPrice: reduce(passValue(detailData), (acc, item) => acc + item.total, 0),
        }
      }

      return {
        groupDetailData: groupItem(passValue(detailData)),
        flattenDiscountData: omit(groupDiscount(discountData), ['cashback']),
        totalItemsPrice: reduce(passValue(detailData), (acc, item) => acc + item.total, 0),
      }
    })

    return {
      showControl,
      expand,
      displayData,
      chargeType,
      enableCashbackDiscount,
      usedClassTickets,
      showDetail,
      checkoutDetailData,
      cashbackUse,
    }
  },
})
</script>

<style lang="postcss" scoped>
.appointment-checkout-receipt {
  @apply flex flex-col gap-[12px];
}
.detail-row-container {
  @apply flex flex-col gap-[6px];
}

.detail-row {
  @apply flex items-center justify-between;
}

.sub-detail-row {
  @apply flex items-center justify-between;
  @apply text-sub text-gray-60;
}
</style>
