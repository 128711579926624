<template>
  <div v-show="chargeType !== 'none'" class="payment-methods">
    <p class="font-medium">{{ displayData.formItemLabel.paymentMethod }}</p>
    <BaseElFormItem prop="payment.method">
      <BaseElRadioGroup v-model="context.payment.method" data-testid="formData_payment">
        <div class="flex flex-col" style="gap: 12px; margin-top: 12px">
          <BaseElRadio
            v-for="payment in displayPaymentOptions"
            :key="payment.value"
            :label="payment.value"
            :disabled="payment.disabled"
          >
            <span v-if="payment.value === 'wallet'">{{ payment.name }}（本次使用：${{ orderCheckoutPrice }} / 使用後餘額：${{
              memberData.wallet.balance - orderCheckoutPrice
            }}）</span>
            <span v-else-if="payment.value === 'externalWallet'">{{ payment.name }}（本次使用：${{ orderCheckoutPrice }} / 使用後餘額：${{ memberData.externalWallet.balance - orderCheckoutPrice }}）</span>
            <span v-else>{{ payment.name }}</span>
          </BaseElRadio>
        </div>
      </BaseElRadioGroup>
    </BaseElFormItem>
  </div>
</template>

<script>
import { computed, defineComponent, nextTick, onMounted, watch } from 'vue'
import { paymentOptionsConfig } from '@/config/payment'
import { map, omit, filter, get } from 'lodash'
import { useCreateAappointment } from '@/use/useCreateAppointment'
import { useExternalWallet } from '@/use/useExternalWallet'

export default defineComponent({
  name: 'AppointmentPaymentMethodsBlock',
  setup (props, { emit }) {
    const { context, memberData, orderCheckoutPrice, allCartItemReady, loading } = useCreateAappointment()
    const { externalWalletName, getExternalWalletConfig, enableExternalWallet } = useExternalWallet()
    const chargeType = computed(() => get(context.checkoutDetail, 'chargeType'))
    const displayData = computed(() => {
      return {
        formItemLabel: {
          paymentMethod: '付款方式',
        },
      }
    })

    // ?? 顯示的付款方式
    const displayPaymentOptions = computed(() => {
      const omitPayments = ['COD', 'atm', 'pchomepay', 'ezAIO']
      if (!enableExternalWallet.value) omitPayments.push('externalWallet')
      const options = map(omit(paymentOptionsConfig, omitPayments), (i) => ({
        name: i.value === 'externalWallet' ? externalWalletName.value : i.name,
        value: i.value,
        type: i.type,
      }))
      if (!orderCheckoutPrice.value) return [{ name: '免付款', value: 'free' }]
      if (!context.member) return options.filter((i) => i.type === 'offline')
      const availables = options.filter((i) => i.type === 'offline' || i.type === 'wallet')

      return map(availables, (item) => {
        return {
          ...item,
          disabled: disabledControl(item.value),
        }
      })
    })

    const disabledControl = (paymentValue) => {
      if (paymentValue === 'wallet') {
        const walletBalance = memberData.wallet.balance
        const left = walletBalance - orderCheckoutPrice.value
        return walletBalance === 0 || left < 0
      }
      if (paymentValue === 'externalWallet') {
        const externalWalletBalance = memberData.externalWallet.balance
        if (!externalWalletBalance) return true
        const externalWalletLeft = externalWalletBalance - orderCheckoutPrice.value
        if (externalWalletLeft < 0) return true
      }
      return false
    }

    // => 自動預設選擇付款方式
    const defaultSelectPayment = () => {
      const availables = filter(displayPaymentOptions.value, (i) => !i.disabled)
      if (availables.length) {
        context.payment.method = availables[0].value
      }
    }

    watch(() => loading.checkoutDetail, async () => {
      await nextTick()
      setTimeout(() => {
        defaultSelectPayment()
      }, 0)
    })
    onMounted(() => {
      getExternalWalletConfig()
    })
    return {
      chargeType,
      context,
      displayPaymentOptions,
      orderCheckoutPrice,
      memberData,
      displayData,
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
