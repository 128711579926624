<template>
  <div>
    <!-- <p v-if="showControl.checkoutReceipt" class="font-medium" style="margin-bottom: 12px">結帳明細</p> -->
    <p class="font-medium" style="margin-bottom: 12px">結帳明細</p>

    <div v-loading="loading.checkoutDetail" class="flex flex-col" style="gap: 24px">
      <!-- <AppointmentCheckoutReceipt v-if="showControl.checkoutReceipt" /> -->
      <AppointmentCheckoutReceipt />

      <div v-show="showControl.checkoutPrice" class="flex items-center justify-between">
        <p>{{ displayData.totalPriceTitle }}</p>
        <p class="text-primary-100">$ {{ displayData.totalPrice }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue'
import AppointmentCheckoutReceipt from './AppointmentCheckoutReceipt.vue'
import { useCreateAappointment } from '@/use/useCreateAppointment'
import { get } from 'lodash'

export default defineComponent({
  name: 'AppointmentCheckoutDetailBlock',
  components: {
    AppointmentCheckoutReceipt,
  },
  setup (props, { emit }) {
    const { context, orderCheckoutPrice, loading } = useCreateAappointment()
    const chargeType = computed(() => get(context.checkoutDetail, 'chargeType'))
    const displayData = computed(() => {
      let totalPriceTitle = '實付金額'

      if (chargeType.value === 'deposit') {
        totalPriceTitle = '實付訂金'
      }

      return {
        totalPriceTitle,
        totalPrice: orderCheckoutPrice.value,
      }
    })

    const showControl = computed(() => {
      const controls = {
        checkoutReceipt: true,
        checkoutPrice: true,
      }

      if (chargeType.value === 'none' || chargeType.value === 'deposit') {
        controls.checkoutReceipt = false
      }

      if (chargeType.value === 'none') {
        controls.checkoutPrice = false
      }

      return controls
    })
    return { displayData, context, loading, showControl }
  },
})
</script>

<style lang="postcss" scoped>

</style>
