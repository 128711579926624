import { createGlobalState } from '@vueuse/core'
import { nanoid } from 'nanoid'
import { computed, reactive, ref } from 'vue'
import { serviceTypeConfig } from '@/config/reservation'
import { useFetch } from '@/use/fetch'
import { GetServiceUnit } from '@/api/serviceUnit'
import { useShop } from './shop'
import { AppointmentCalculateItemizedDetails, AppointmentOrderCalculateDetailV2, FindReservationParameter, GetReservationCheckoutConfig, GetReservationCheckoutInvoiceConfig } from '@/api/reservation'
import { apiFormatAdaptor } from '@/utils/api'
import { cloneDeep, filter, find, findIndex, flatten, forEach, get, isArray, isEmpty, isNull, isUndefined, map, omit, set, toNumber } from 'lodash'
import { GetService } from '@/api/service'
import { GetClassTicketRecord } from '@/api/classTicket'
import { FindMemberRebateWallet } from '@/api/rebate'
import { FindMember } from '@/api/member'
import { usePermissions } from './permissions'
import debounce from 'lodash.debounce'
import formUtils from '@/utils/form'
import { passValue } from '@/utils/helper'

export const featureKeys = {
  useDeposit: 'adminView.appointmentDeposit.enable',
  useClassTicket: 'admin.classTicketRecord.find',
  useCheckoutConfig: 'admin.appointmentCheckoutConfig.singleFind',
  useCheckoutPayment: 'admin.appointmentCheckoutPaymentConfig.find',
  useCheckoutInvoice: 'admin.appointmentCheckoutInvoiceConfig.singleFind',
  useOrderCashbackDiscount: 'adminView.appointmentOrder.checkoutCashbackDeduction',
  useCashbackWallet: 'admin.member.findOneCashbackWallet',
}

export const appointmentModeType = {
  normal: 'normal',
  otherReservation: 'multiServices',
  batchOrder: 'multiPeriod',
}

export const useAppointmentCartItem = () => {
  const data = reactive({
    id: null,
    cartItem: {},
    formRef: null,
  })
  const createAppointmentCartItem = (payload) => {
    const newItem = new AppointmentCartItem(payload)
    data.cartItem = newItem
    data.id = newItem.nanoId
  }

  return { data, createAppointmentCartItem }
}

export const useCreateAappointment = createGlobalState(() => {
  const { fetchAllOld, simpleFetch } = useFetch()
  const { checkAction } = usePermissions()
  const { shopId } = useShop()
  const context = reactive({
    userType: 'member',
    member: null,
    guest: {
      name: null,
      phone: null,
    },
    peopleCount: 1,
    appointmentMode: 'normal',
    comment: null,
    shopNote: null,
    payment: {
      method: null,
    },
    discount: {
      cashback: {
        use: null,
        limit: null,
      },
    },
    checkoutDetail: null,
    invoice: {
      type: 'C',
      companyTitle: null,
      taxId: null,
      email: null,
    },
    periodSettingFormRefs: [],
    otherUserInfo: {
      formRef: null,
      formData: {},
    },
    isProcessing: false,
  })
  const loading = reactive({
    checkoutDetail: false,
  })

  // 購物車
  const cart = ref([])
  // 設定相關資料
  const configData = reactive({
    reservation: {},
    checkout: {},
    checkoutInvoice: {},
  })
  // 資源相關資料
  const resourceData = reactive({
    classTickets: [],
    serviceUnits: [],
    services: [],
  })
  // 會員相關資料
  const memberData = reactive({
    classTickets: [],
    cashback: {
      balance: null,
    },
    wallet: {
      balance: null,
    },
    externalWallet: {
      balance: null,
    },
  })

  const useFeatures = computed(() => {
    return {
      deposit: checkAction(featureKeys.useDeposit),
      serviceClassTicket: checkAction(featureKeys.useClassTicket),
      checkoutConfig: checkAction(featureKeys.useCheckoutConfig),
      checkoutPayment: checkAction(featureKeys.useCheckoutPayment),
      checkoutInvoice: checkAction(featureKeys.useCheckoutInvoice),
      cashbackDiscount: checkAction(featureKeys.useOrderCashbackDiscount),
    }
  })
  const resetContext = (keys) => {
    const initialContext = {
      userType: 'member',
      member: null,
      guest: {
        name: null,
        phone: null,
      },
      peopleCount: 1,
      appointmentMode: 'normal',
      comment: null,
      shopNote: null,
      payment: {
        method: null,
      },
      discount: {
        cashback: {
          use: null,
          limit: null,
        },
      },
      checkoutDetail: null,
      invoice: {
        type: 'C',
        companyTitle: null,
        taxId: null,
        email: null,
      },
      periodSettingFormRefs: [],
      otherUserInfo: {
        formRef: null,
        formData: {},
      },
    }

    if (!keys || !isArray(keys)) {
      forEach(context, (val, key) => {
        set(context, key, initialContext[key])
      })
    } else {
      forEach(keys, key => {
        set(context, key, initialContext[key])
      })
    }
  }

  // 重置購物車
  const resetCart = () => {
    cart.value = []
    const { createAppointmentCartItem, data } = useAppointmentCartItem()
    createAppointmentCartItem()
    cart.value.push(data)
  }

  // 更新指定購物車資料
  const updateCartItemData = (itemId, key, data) => {
    const targetItem = find(cart.value, { id: itemId })
    if (!targetItem) return
    set(targetItem, key, data)
  }

  const findCartItem = (nanoId) => {
    return find(cart.value, { nanoId })
  }

  const pruneAfterCartItem = (itemId) => {
    const idx = findIndex(cart.value, { id: itemId })
    if (idx === -1) return
    cart.value = cart.value.slice(0, idx + 1)
  }

  const addAppointmentCartItem = (payload) => {
    const { createAppointmentCartItem, data } = useAppointmentCartItem()
    createAppointmentCartItem(payload)
    cart.value.push(data)
    return data
  }
  const removeAppointmentCartItem = (nanoId) => {
    cart.value = filter(cart.value, (item) => item.id !== nanoId)
  }

  const getResoruceData = async () => {
    const callList = []

    // 所有服務人員
    callList.push(async () => {
      await fetchAllOld(GetServiceUnit, { shopId: shopId.value }, (res) => {
        resourceData.serviceUnits = res
      })
    })

    // 所有服務項目
    callList.push(async () => {
      await fetchAllOld(GetService, { shopId: shopId.value }, (res) => {
        resourceData.services = res
      })
    })

    await Promise.all(callList.map((call) => call()))
  }

  // 取得會員資源資料
  const getMemberResourceData = async (resource) => {
    if (!context.member) return
    // 會員擁有的堂票
    if (resource === 'classTickets') {
      if (!checkAction(featureKeys.useClassTicket)) return
      await fetchAllOld(GetClassTicketRecord, {
        shopId: shopId.value,
        MemberId: context.member.id,
      }, (res) => {
        memberData.classTickets = res
      })
    }

    // 會員回饋金
    if (resource === 'cashback') {
      if (!checkAction(featureKeys.useCashbackWallet)) return
      const [res, err] = await FindMemberRebateWallet({
        shopId: shopId.value,
        memberId: context.member.id,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      if (res) {
        memberData.cashback.balance = res.balance
      }
    }

    // 會員
    if (resource === 'member') {
      const [res, err] = await apiFormatAdaptor(FindMember)({
        shopId: shopId.value,
        id: context.member.id,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      memberData.wallet.balance = get(res, 'Wallet.balance')
    }
  }

  // 取得設定資料
  const getConfigData = async () => {
    const callList = []
    const basePayload = { shopId: shopId.value }
    callList.push(async () => {
      const [res, err] = await apiFormatAdaptor(FindReservationParameter)({ shopId: shopId.value })
      if (err) {
        window.$message.error(err)
        return
      }
      if (res)configData.reservation = res
    })

    callList.push(async () => {
      if (!checkAction(featureKeys.useCheckoutConfig)) return
      simpleFetch(GetReservationCheckoutConfig, basePayload, (res) => {
        configData.checkout = res
      })
    })

    callList.push(async () => {
      if (!checkAction(featureKeys.useCheckoutInvoice)) return
      simpleFetch(GetReservationCheckoutInvoiceConfig, basePayload, (res) => {
        configData.checkoutInvoice = res
      })
    })
    await Promise.all(callList.map((call) => call()))
  }

  // 取得 cartItem 之前的所有預約資料
  const getCartItemPreReservationsPayload = (nanoId) => {
    const cartItemIdx = findIndex(cart.value, { id: nanoId })

    // 列出 cartItemIdx 之前的所有 cartItem 的預約資料
    const preReservations = []
    for (let i = 0; i < cartItemIdx; i++) {
      const cartItem = get(cart.value, `${[i]}.cartItem`)

      const reservationDetail = cartItem.getDetailData('reservation')

      for (const reservation of reservationDetail.reservations) {
        preReservations.push({
          appointmentUnitId: get(reservation, 'appointmentUnitId'),
          resourceItemId: get(reservation, 'ResourceItem.id'),
          start: get(reservation, 'start'),
          totalBookingTime: get(reservation, 'totalBookingTime') || get(reservation, 'resourceItemTotalBookingTime'),
        })
      }
    }

    return preReservations
  }

  // 組合所有 cartItem 的預約資料
  const composeAllCartItemReservations = computed(() => {
    const reservations = map(cart.value, (item) => {
      const cartItem = item.cartItem
      const data = []
      const reservationData = get(cartItem.getDetailData('reservation'), 'reservations')
      const classTickets = get(cartItem, 'classTickets')
      if (!reservationData) return []

      let count = 0
      for (const reservation of reservationData) {
        data.push({
          appointmentServiceId: get(reservation, 'appointmentServiceId'),
          appointmentSubServiceId:
            get(reservation, 'appointmentSubServiceId') || undefined,
          appointmentServiceAttachIds: get(reservation, 'appointmentServiceAttachIds'),
          useClassTicketRecordId: count === 0 ? get(classTickets, '[0].id') || undefined : undefined, // FIXME 只有第一筆使用堂票 (暫時)
          start: get(reservation, 'start'),
        })
        count++
      }
      return data
    })

    return flatten(reservations)
  })

  // 訂單總額 (含折扣)
  const orderCheckoutPrice = computed(() => {
    const totalPrice = get(context, 'checkoutDetail.price')
    return totalPrice ? toNumber(totalPrice) : 0
  })

  // 所有 cartItem 是否都已經完成基本資料填寫
  const allCartItemReady = computed(() => {
    let ready = true
    forEach(cart.value, (item) => {
      const cartItem = item.cartItem
      if (!cartItem.getDetailData('reservation')) ready = false
    })

    return ready
  })

  // 組合批次訂單 payload
  const formatBatchOrderPayload = computed(() => {
    let paymentCode = get(context, 'payment.method')
    let paymentComment
    const chargeType = get(configData.reservation, 'chargeType')
    const orders = []
    forEach(cart.value, (item) => {
      const cartItem = item.cartItem
      const itemReservationDetail = cartItem.getDetailData('reservation')

      const order = {
        peopleCount: get(itemReservationDetail, 'peopleCount'),
        reservations: map(cartItem.formatReservationsPayload(context), (reservationPayload) => {
          return {
            ...reservationPayload,
            isOvertime: cartItem.isOverTime,
            isOther: false,
          }
        }),
      }

      orders.push(order)
    })

    if (context.payment.method.includes('offline')) {
      paymentCode = 'offline'
      paymentComment = context.payment.method
    }

    const payload = {
      shopId: shopId.value,
      orders,
      userType: context.userType,
      memberId: get(context, 'member.id'),
      userName: get(context, 'member.UserInfo.name'),
      userPhone: get(context, 'member.UserInfo.phone'),
      userComment: context.comment || undefined,
      adminComment: context.shopNote || undefined,
      chargeType,
      paymentCode,
      paymentComment,
      paymentPrice: orderCheckoutPrice.value,
      // paymentEmail,
      cashbackDeductionAmount: get(context, 'discount.cashback.use') || undefined,
      invoiceInfo: {
        buyerType: context.invoice.type,
        buyerEmail: context.invoice.email,
        buyerName: context.invoice.companyTitle || undefined,
        buyerIdentifier: context.invoice.taxId || undefined,
      },
    }
    if (!get(configData.checkoutInvoice, 'enable') || chargeType !== 'checkout') {
      delete payload.invoiceInfo
    }

    if (['free', 'wallet', 'externalWallet'].includes(paymentCode)) {
      delete payload.invoiceInfo
    }

    return payload
  })
  // 組合一般訂單 payload
  const formatOrderPayload = computed(() => {
    let paymentCode = get(context, 'payment.method')
    let paymentComment

    const chargeType = get(configData.reservation, 'chargeType')

    const reservations = []
    forEach(cart.value, (item) => {
      const cartItem = item.cartItem
      const formatedReservations = map(cartItem.formatReservationsPayload(context), (reservationPayload) => {
        return {
          ...reservationPayload,
          isOvertime: cartItem.isOverTime,
          isOther: cartItem.isOther,
        }
      })

      reservations.push(formatedReservations)
    })

    if (context.payment.method.includes('offline')) {
      paymentCode = 'offline'
      paymentComment = context.payment.method
    }

    const payload = {
      shopId: shopId.value,
      reservations: flatten(reservations),
      userType: context.userType,
      memberId: get(context, 'member.id'),
      userName: get(context, 'member.UserInfo.name'),
      userPhone: get(context, 'member.UserInfo.phone'),
      userComment: context.comment || undefined,
      adminComment: context.shopNote || undefined,
      peopleCount: context.peopleCount,
      chargeType,
      paymentCode,
      paymentComment,
      paymentPrice: orderCheckoutPrice.value,
      cashbackDeductionAmount: get(context, 'discount.cashback.use') || undefined,
      invoiceInfo: {
        buyerType: context.invoice.type,
        buyerEmail: context.invoice.email,
        buyerName: context.invoice.companyTitle || undefined,
        buyerIdentifier: context.invoice.taxId || undefined,
      },
    }

    if (!get(configData.checkoutInvoice, 'enable') || chargeType !== 'checkout') {
      delete payload.invoiceInfo
    }

    if (['free', 'wallet', 'externalWallet'].includes(paymentCode)) {
      delete payload.invoiceInfo
    }

    return payload
  })

  // 預設選擇堂票
  const defaultSelectClassTicket = async ({ displayClassTicketOptions, cartItem }) => {
    const firstOptions = get(displayClassTicketOptions, '[0]')
    const secondOptions = get(displayClassTicketOptions, '[1]')
    if (!firstOptions) return
    const preUsed = filter(cart.value, (item) => {
      const same = find(item.cartItem.classTickets, { id: firstOptions.id })
      return same
    })

    if (firstOptions.availableUseTimes - preUsed.length <= 0) {
      if (secondOptions) cartItem.classTickets = [secondOptions]
    } else {
      cartItem.classTickets = [firstOptions]
    }
  }

  const refreshOrderPrice = debounce(async () => {
    context.isProcessing = true
    let cashbackUse = context.discount.cashback.use
    const cashbackLimit = context.discount.cashback.limit
    if (cashbackUse > cashbackLimit) {
      context.isProcessing = false
      return
    }

    loading.checkoutDetail = true

    if (isNull(cashbackUse) || isUndefined(cashbackUse) || !cashbackUse) {
      cashbackUse = 0
    }

    const orders = []
    const appointmentMode = get(context, 'appointmentMode')

    const omitFields = [
      'appointmentUnitId',
      'setTotalBookingTime',
      'setResourceItemTotalBookingTime',
      'classTicketRecordId',
      'resourceItemId',
      'isOvertime',
      'isOther',
      'userName',
      'userPhone',
      'fromNotSpecify',
    ]

    if ([appointmentModeType.normal, appointmentModeType.otherReservation].includes(appointmentMode)) {
      orders.push({
        reservations: map(composeAllCartItemReservations.value, (item) => {
          const data = omit(item, omitFields)
          const classTicketRecordId = get(item, 'classTicketRecordId')
          if (classTicketRecordId) data.useClassTicketRecordId = passValue(get(item, 'classTicketRecordId'))
          delete data.classTicketRecordId
          return data
        }),
      })
    } else if (appointmentMode === appointmentModeType.batchOrder) {
      for (const item of cart.value) {
        orders.push({
          reservations: map(item.cartItem.formatReservationsPayload(context), (item) => {
            const data = omit(item, omitFields)
            const classTicketRecordId = get(item, 'classTicketRecordId')
            if (classTicketRecordId) data.useClassTicketRecordId = passValue(get(item, 'classTicketRecordId'))
            delete data.classTicketRecordId
            return data
          }),
        })
      }
    }

    const callList = [
      async () => {
        const [res, err] = await AppointmentOrderCalculateDetailV2({
          shopId: shopId.value,
          memberId: get(context.member, 'id'),
          orders,
          cashbackDeductionAmount: toNumber(cashbackUse),
        })
        loading.checkoutDetail = false
        if (err) {
          window.$message.error(err)
          return
        }
        context.checkoutDetail = res
      },
      // async () => {
      //   const [res, err] = await AppointmentCalculateItemizedDetails({
      //     shopId: shopId.value,
      //     memberId: get(context.member, 'id'),
      //     orders,
      //     cashbackDeductionAmount: toNumber(cashbackUse),
      //   })
      //   console.log('new Details', res)
      // }
    ]

    await Promise.all(callList.map((call) => call()))
    context.isProcessing = false
  }, 300, { leading: false, trailing: true })

  // 初始化
  const init = async () => {
    await Promise.allSettled([
      getResoruceData(),
      getConfigData(),
    ])
    if (!cart.value.length) {
      const { data, createAppointmentCartItem } = useAppointmentCartItem()
      createAppointmentCartItem()
      cart.value.push(data)
    }
  }

  const clearAllFormFieldsValidate = async () => {
    const callList = []
    callList.push(async () => {
      for (const formRef of context.periodSettingFormRefs) {
        formUtils.clearValidate(formRef)
      }
    })

    callList.push(async () => {
      for (const cartItem of cart.value) {
        const formRef = get(cartItem, 'formRef')
        if (formRef) formUtils.clearValidate(formRef)
      }
    })

    if (context.userType === 'guest' && context.peopleCount > 1) {
      callList.push(async () => {
        const formRef = get(context.otherUserInfo, 'formRef')
        if (formRef) formUtils.clearValidate(formRef)
      })
    }

    await Promise.all(callList.map((call) => call()))
  }

  return {
    init,
    loading,
    context,
    cart,
    configData,
    resetContext,
    addAppointmentCartItem,
    removeAppointmentCartItem,
    findCartItem,
    resourceData,
    memberData,
    resetCart,
    getMemberResourceData,
    getCartItemPreReservationsPayload,
    composeAllCartItemReservations,
    orderCheckoutPrice,
    formatBatchOrderPayload,
    formatOrderPayload,
    allCartItemReady,
    updateCartItemData,
    defaultSelectClassTicket,
    refreshOrderPrice,
    pruneAfterCartItem,
    clearAllFormFieldsValidate,
  }
})

export class AppointmentCartItem {
  constructor (data = {}) {
    this.nanoId = nanoid(6)
    this.serviceType = data.serviceType || serviceTypeConfig.humanService.value // 服務性質
    this.serviceUnit = data.serviceUnit // 服務人員
    this.service = data.service // 服務項目
    this.subService = data.subService // 服務子項目
    this.attachedServices = data.attachedServices || [] // 附加服務
    this.resources = data.resources || [] // 服務設備
    this.classTickets = data.classTickets || [] // 堂票
    this.date = data.date // 日期
    this.time = data.time // 時間
    this.isOverTime = data.isOverTime || false // 是否為加班
    this.isOther = data.isOther || false // 是否為其他

    this.bookingTime = data.bookingTime || 0 // 預估服務時數
    this.resourceBookingTime = data.resourceBookingTime || 0 // 預估設備時數

    this.detailData = {
      service: null,
      serviceUnit: null,
      reservation: null,
    }
  }

  setDetailData (key, data) {
    this.detailData[key] = data
  }

  getDetailData (key) {
    return this.detailData[key]
  }

  resetData (keys) {
    if (!keys || !isArray(keys)) {
      this.serviceUnit = null
      this.service = null
      this.subService = null
      this.attachedServices = []
      this.resources = []
      this.classTickets = []
      this.date = null
      this.time = null
      this.isOverTime = false
      // this.isOther = false
      this.bookingTime = 0
      this.resourceBookingTime = 0
      return
    }
    keys.forEach(key => {
      if (['attachedServices', 'resources', 'classTickets'].includes(key)) this[key] = []
      else if (['bookingTime', 'resourceBookingTime'].includes(key)) this[key] = 0
      else if (['isOther', 'isOverTime'].includes(key)) this[key] = false
      else this[key] = null
    })
  }

  resetDetailData (keys) {
    if (isArray(keys)) {
      keys.forEach(key => {
        this.detailData[key] = null
      })
    } else {
      this.detailData.service = null
      this.detailData.serviceUnit = null
      this.detailData.reservation = null
    }
  }

  // getters
  isResourceService () {
    return [
      serviceTypeConfig.resourceService.value,
      serviceTypeConfig.humanAndResourceService.value,
    ].includes(this.serviceType)
  }

  isHumanResourceService () {
    return [
      serviceTypeConfig.humanAndResourceService.value,
    ].includes(this.serviceType)
  }

  getAppointmentTotalTime () {
    // bookingTime
    // 初始值為 0
    // 如果有子服務的話，則加上子服務的時間 (bookingTime)
    // 如果沒有子服務的話，則加上服務的時間 (bookingTime)
    // 如果有附加服務的話，則加上所有附加服務的時間 (bookingTime)

    let totalTime = 0

    if (this.subService) {
      totalTime += get(this.subService, 'bookingTime', 0)
    } else {
      totalTime += get(this.service, 'bookingTime', 0)
    }

    if (this.attachedServices.length) {
      forEach(this.attachedServices, (attachedService) => {
        totalTime += get(attachedService, 'bookingTime', 0)
      })
    }

    return totalTime
  }

  getAppointmentResourceTotalTime () {
    let totalTime = 0

    if (this.subService) {
      totalTime += get(this.subService, 'bookingTime', 0)
    } else {
      totalTime += get(this.service, 'bookingTime', 0)
    }

    if (this.attachedServices.length) {
      const attachServiceSettings = get(this.service, 'attachServiceSettings', [])
      forEach(this.attachedServices, (attachedService) => {
        const enableResourceItemBooking = get(find(attachServiceSettings, {
          appointmentServiceAttachId: get(attachedService, 'id'),
        }), 'enableResourceItemBooking')
        if (enableResourceItemBooking) totalTime += get(attachedService, 'bookingTime', 0)
      })
    }

    return totalTime
  }

  getAvailableTimesPayload ({ start, end, notSpecifyUnits, peopleCount, preReservations }) {
    let appointmentUnitIds
    let resourceItemIds
    const selectedServiceUnitId = get(this.serviceUnit, 'id')
    if (selectedServiceUnitId === 'all') appointmentUnitIds = null
    else if (selectedServiceUnitId === 'notSpecify') appointmentUnitIds = map(notSpecifyUnits, 'id')
    else {
      if (selectedServiceUnitId) appointmentUnitIds = [selectedServiceUnitId]
    }

    if (this.isResourceService()) {
      if (find(this.resources, { id: 'all' })) resourceItemIds = null
      else resourceItemIds = map(this.resources, 'id')
    }

    return {
      rangeStart: start,
      rangeEnd: end,
      appointmentUnitIds,
      resourceItemIds,
      appointmentServiceId: get(this.service, 'id') || undefined,
      appointmentSubServiceId: get(this.subService, 'id'),
      appointmentServiceAttachIds: map(this.attachedServices, 'id'),
      setTotalBookingTime: this.isResourceService() ? undefined : this.bookingTime,
      setResourceItemTotalBookingTime: this.isResourceService() ? this.resourceBookingTime : undefined,
      peopleCount,
      preReservations,
    }
  }

  getPreDetailByStartPayload ({ notSpecifyUnits, preReservations, peopleCount } = { notSpecifyUnits: [], preReservations: [], peopleCount: 1 }) {
    let appointmentUnitIds
    let resourceItemIds
    let useNotSpecifyRule = false
    const selectedServiceUnitId = get(this.serviceUnit, 'id')
    if (selectedServiceUnitId === 'all') appointmentUnitIds = null
    else if (selectedServiceUnitId === 'notSpecify') {
      appointmentUnitIds = map(notSpecifyUnits, 'id')
      useNotSpecifyRule = true
    } else {
      if (selectedServiceUnitId) appointmentUnitIds = [selectedServiceUnitId]
    }

    if (this.isResourceService()) {
      if (find(this.resources, { id: 'all' })) resourceItemIds = null
      else resourceItemIds = map(this.resources, 'id')
    }

    return {
      start: this.time,
      appointmentUnitIds,
      resourceItemIds,
      appointmentServiceId: get(this.service, 'id') || undefined,
      appointmentSubServiceId: get(this.subService, 'id'),
      appointmentServiceAttachIds: map(this.attachedServices, 'id'),
      peopleCount,
      isOvertime: this.isOverTime,
      useNotSpecifyRule,
      preReservations,
      setTotalBookingTime: this.isResourceService() ? undefined : this.bookingTime,
      setResourceItemTotalBookingTime: this.isResourceService() ? this.resourceBookingTime : undefined,
    }
  }

  formatReservationsPayload (context) {
    const reservations = get(this.getDetailData('reservation'), 'reservations', [])

    let userName
    let userPhone
    const memberName = get(context, 'member.UserInfo.name')
    const memberPhone = get(context, 'member.UserInfo.phone')
    const guestName = get(context, 'guest.name')
    const guestPhone = get(context, 'guest.phone')
    if (context.userType === 'member') {
      userName = memberName
      userPhone = memberPhone
    } else if (context.userType === 'guest') {
      userName = guestName
      userPhone = guestPhone
    }

    const otherUserInfo = get(context, 'otherUserInfo.formData')

    return map(reservations, (reservation, idx) => {
      if (context.peopleCount > 1) {
        if (idx > 0) {
          if (!isEmpty(otherUserInfo)) {
            const name = get(otherUserInfo[`user_${idx}`], 'name')
            const phone = get(otherUserInfo[`user_${idx}`], 'phone')
            if (name && phone) {
              userName = name
              userPhone = phone
            }
          }
        }
      }

      return {
        appointmentUnitId: get(reservation, 'appointmentUnitId'),
        resourceItemId: get(reservation, 'ResourceItem.id'),
        appointmentServiceId: get(reservation, 'appointmentServiceId'),
        appointmentSubServiceId: get(reservation, 'appointmentSubServiceId') || undefined,
        appointmentServiceAttachIds: get(reservation, 'appointmentServiceAttachIds'),
        classTicketRecordId: idx === 0 ? get(this.classTickets, '[0].id') : undefined,
        start: get(reservation, 'start'),
        userName,
        userPhone,
        isOvertime: this.isOverTime,
        isOther: this.isOther,
        fromNotSpecify: get(reservation, 'fromNotSpecify'),
        setTotalBookingTime: get(reservation, 'totalBookingTime'),
        setResourceItemTotalBookingTime: get(reservation, 'resourceItemTotalBookingTime'),
      }
    })
  }
}
